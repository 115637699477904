import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.css"

const CreatorCategoryList = ({ title, children }) => (
  <ul
    className={styles.categoryList}
    style={{
      display: `flex`,
      flexDirection: `row`,
      listStyleType: `none`,
      margin: `-0.4375rem 0 0.4375rem 0`,
      padding: `0`,
      justifyContent: `center`,
    }}
  >
    {title && (
      <h3
        className={styles.title}
        style={{
          fontSize: `0.75rem`,
          color: `rgba(0, 169, 197, 0.625)`,
          textTransform: `uppercase`,
          letterSpacing: `0.1rem`,
          margin: `0.25rem 0.5rem 0 0`,
        }}
      >
        {`${title}:`}
      </h3>
    )}
    <ul
      className={styles.categoryListItems}
      style={{
        display: `inherit`,
        flexDirection: `inherit`,
        listStyleType: `inherit`,
        padding: `0`,
      }}
    >
      {children}
    </ul>
  </ul>
)

export default CreatorCategoryList

CreatorCategoryList.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}
