import React, { useCallback } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageNav from "../components/PageNav"
import Search from "../components/Search"
import Categories from "../components/Categories"
import CreatorCategoryList from "../components/CreatorCategoryList"
import CategoryListItem from "../components/CategoryListItem"
import CreatorList from "../components/CreatorList"
import Button from "../components/Button"

import { useStore } from "../store/useStore"

import * as inlineStyles from "../components/Layout/inlineStyles"
import styles from "./upphovspersoner.module.css"

const Upphovspersoner = props => {
  const { state, dispatch } = useStore()
  const {
    creatorsToShow,
    //selectedAgeGroups,
    //selectedBookCategories,
    selectedCreatorRoles,
    selectedLastNameLetters,
    creatorsSearchQuery,
    creatorsSearchResults,
  } = state

  const addCreatorsToShow = useCallback(
    () => dispatch({ type: "addCreatorsToShow" }),
    [dispatch]
  )

  const resetCreatorsList = useCallback(() => {
    dispatch({ type: "resetCreatorsList" })
  }, [dispatch])

  const setCreatorRoles = useCallback(
    selectedOption => {
      dispatch({ type: "setCreatorRoles", payload: selectedOption })
    },
    [dispatch]
  )

  const creators = props.data.allWordpressWpCreators.edges.map(
    creatorItem => creatorItem.node
  )

  const creatorRoles = props.data.allWordpressWpCreatorRoles.edges.map(
    creatorRoleItem => creatorRoleItem.node
  )

  const creatorsFilteredBySearch = creatorsSearchQuery
    ? creators.filter(b => creatorsSearchResults.map(r => r.id).includes(b.id))
    : creators

  const filteredCreators = creatorsFilteredBySearch.filter(b => {
    if (!selectedCreatorRoles) {
      return true
    }
    const selectedCreatorRolesWordpressIds = creatorRoles.find(
      c => c.slug === selectedCreatorRoles
    ).wordpressId
    if (
      b.creatorRoles &&
      b.creatorRoles.includes(selectedCreatorRolesWordpressIds)
    ) {
      return true
    }
  })
  const slicedFilteredCreators = filteredCreators.slice(0, creatorsToShow)

  const handleCreatorRoleChange = type => {
    setCreatorRoles(type)
  }

  return (
    <Layout>
      <SEO title="Upphovspersoner" />
      <PageNav>
        <Search
          name="creatorsQuery"
          placeholder="Sök upphovspersoner"
          ariaLabel="Sök upphovspersoner"
          autoComplete={false}
          creatorsSearch
          engine={{
            indexStrategy: "Prefix match",
            searchSanitizer: "Lower Case",
            LastNameIndex: true,
            FirstNameIndex: true,
            TitleIndex: true,
            CreatorsIndex: false,
            IsbnIndex: false,
            SearchByTerm: true,
          }}
          dataset={creators.map(b => {
            return {
              id: b.id,
              title: b.title,
            }
          })}
        />
        <Categories>
          <CreatorCategoryList>
            <CategoryListItem
              active={!selectedCreatorRoles}
              onClick={() => handleCreatorRoleChange(null)}
            >
              Alla
            </CategoryListItem>
            <CategoryListItem
              active={selectedCreatorRoles === "author"}
              onClick={() => handleCreatorRoleChange("forfattare")}
            >
              Författare
            </CategoryListItem>
            <CategoryListItem
              active={selectedCreatorRoles === "illustrator"}
              onClick={() => handleCreatorRoleChange("illustrator")}
            >
              Illustratörer
            </CategoryListItem>
          </CreatorCategoryList>
          {/*}
          <CreatorCategoryList>
            <CategoryListItem padding={`0.25rem 0`}>A</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>B</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>C</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>D</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>E</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>F</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>G</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>H</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>I</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>J</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>K</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>L</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>M</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>N</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>O</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>P</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>Q</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>R</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>S</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>T</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>U</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>V</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>W</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>X</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>Y</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>Z</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>Å</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>Ä</CategoryListItem>
            <CategoryListItem padding={`0.25rem 0`}>Ö</CategoryListItem>
          </CreatorCategoryList>
        */}
        </Categories>
      </PageNav>
      <div className="container" style={inlineStyles.container}>
        {creatorsSearchQuery || selectedCreatorRoles ? (
          <div className={styles.searchResultTitleContainer}>
            <h1 className={styles.resetTitle}>Sökresultat</h1>
            <div className={styles.resetButtonContainer}>
              <button
                type="button"
                className={styles.resetButton}
                onClick={resetCreatorsList}
              >
                Återställ sökning
              </button>
            </div>
          </div>
        ) : (
          <h1 className={styles.title}>Upphovspersoner</h1>
        )}
        {slicedFilteredCreators.length ? (
          <div className={styles.creators}>
            <CreatorList creators={slicedFilteredCreators} />
            <div
              className={`${styles.buttonContainer} ${styles.showMoreContainer}`}
            >
              <Button
                onClick={addCreatorsToShow}
                disabled={creatorsToShow >= filteredCreators.length}
              >
                Visa fler upphovspersoner
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.creators}>
            <div className={styles.noResultsContainer}>
              <p>Inga resultat</p>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Upphovspersoner

export const pageQuery = graphql`
  {
    allWordpressWpCreatorRoles {
      edges {
        node {
          id
          wordpressId: wordpress_id
          slug
        }
      }
    }
    allWordpressWpCreators(
      sort: { fields: [acf___last_name, acf___first_name], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          creatorRoles: creator_roles
          acf {
            firstName: first_name
            lastName: last_name
            portraitImage: portrait_image {
              altText: alt_text
            }
            featuredPortraitImage: featured_portrait_image {
              altText: alt_text
              caption
              localFile {
                childImageSharp {
                  fluid(maxWidth: 178, maxHeight: 178) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
