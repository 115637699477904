import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./styles.module.css"

const CreatorList = ({ creators }) => (
  <ul className={styles.creatorList}>
    {creators.map((creatorItem, creatorIndex) => (
      <li className={styles.creatorListItem} key={creatorIndex}>
        <Link to={`/upphovspersoner/${creatorItem.slug}`}>
          <div className={styles.creator}>
            {creatorItem.acf && creatorItem.acf.featuredPortraitImage ? (
              <div className={styles.portrait}>
                <Img
                  src={
                    creatorItem.acf.featuredPortraitImage.localFile.childImageSharp.src
                  }
                  fluid={
                    creatorItem.acf.featuredPortraitImage.localFile.childImageSharp.fluid
                  }
                  alt={
                    creatorItem.acf.portraitImage.altText ||
                    creatorItem.acf.featuredPortraitImage.altText ||
                    ""
                  }
                />
              </div>
            ) : (
              <img className={styles.emptyPortrait} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt={creatorItem.title} />
            )}
            <h4 className={styles.title}>{creatorItem.title}</h4>
          </div>
        </Link>
      </li>
    ))}
  </ul>
)

export default CreatorList

CreatorList.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.object).isRequired,
}
