import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.css"

const CategoryListItem = ({ children, padding, onClick, active }) => (
  <li className={`${styles.categoryListItem} ${active ? styles.active : null}`}>
    <button
      type="button"
      className={`${styles.button}`}
      style={{
        padding: padding,
        color: `inherit`,
      }}
      onClick={onClick}
    >
      <span
        className={styles.buttonSpan}
        style={{
          padding: `0.375rem 0.75rem`,
          display: `inline-block`,
        }}
      >
        {children}
      </span>
    </button>
  </li>
)

export default CategoryListItem

CategoryListItem.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

CategoryListItem.defaultProps = {
  padding: `0.25rem`,
  onClick: null,
  active: false,
}
