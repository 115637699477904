import React from "react"
import PropTypes from "prop-types"

import styles from "./styles.module.css"

const Categories = ({ children }) => (
  <div
    className={styles.categories}
    style={{
      display: `flex`,
      flexWrap: `wrap`,
      justifyContent: `space-between`,
      marginTop: `1.75rem`,
    }}
  >
    {children}
  </div>
)

export default Categories

Categories.propTypes = {
  children: PropTypes.node.isRequired,
}
